// src/Header.js
import React from 'react';

const Header = () => {
  return (
    <header>
      <h1>Landing Page</h1>
    </header>
  );
};

export default Header;
