// src/MainContent.js
import React from 'react';

const MainContent = () => {
  return (
    <main>
      <section>
        <h2>Welcome to Our Landing Page!</h2>
        <p>This is a simple React landing page example.</p>
      </section>
      <section>
        {/* Add more content or components as needed */}
      </section>
    </main>
  );
};

export default MainContent;
