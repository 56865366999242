// src/App.js
import React from 'react';
import { Helmet } from 'react-helmet';
import Header from './Header';
import MainContent from './MainContent';
import Footer from './Footer';
import './App.css';
import logo from './logo.svg';

function App() {
  return (
    <div className="App">
      {/* <Helmet>
        <meta
          http-equiv="Content-Security-Policy"
          content="frame-ancestors https://*.awsapps.com https://crisis.my.connect.aws"
        />
      </Helmet> */}
      <Header />
      <MainContent />
      <Footer />
    </div>
  );
}

export default App;
